import React, {useMemo} from 'react';
import { scaleQuantize } from '@visx/scale';
import { Mercator } from '@visx/geo';
import * as topojson from 'topojson-client';
import {
  Box,
} from "@chakra-ui/react";
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from "components/card/Card.js";
import { useTopologyData } from 'api';

export const background = 'white';


const Map =  ({ width, height, data, events = false }) => {
  const centerX = width / 2;
  const centerY = height / 2;
  const scale = (width / 630) * 100;

  const world = useMemo(() => topojson.feature(data, data.objects.units), [data]);

  const color = scaleQuantize({
    domain: [
      Math.min(...world.features.map((f) => f.geometry.coordinates.length)),
      Math.max(...world.features.map((f) => f.geometry.coordinates.length)),
    ],
    range: ['#ffb01d', '#ffa020', '#ff9221', '#ff8424', '#ff7425', '#fc5e2f', '#f94b3a', '#f63a48'],
  });

  return width < 10 ? null : (
    <svg width={width} height={height}>
      <rect x={0} y={0} width={width} height={height} fill={background} rx={14} />
      <Mercator
        data={world.features}
        scale={scale}
        translate={[centerX, centerY + 50]}
      >
        {(mercator) => (
          <g>
            {mercator.features.map(({ feature, path }, i) => (
              <path
                key={`map-feature-${i}`}
                d={path || ''}
                fill={color(feature.geometry.coordinates.length)}
                stroke={background}
                strokeWidth={0.5}
                onClick={() => {
                  if (events) alert(`Clicked: ${feature.properties.name} (${feature.id})`);
                }}
              />
            ))}
          </g>
        )}
      </Mercator>
    </svg>
  );
}


export default function Worldwide(props) {
  const { ...rest } = props;
  const { data } = useTopologyData();

  return (
    <Card
      w='100%'
      mb='0px'
      {...rest}
    >
      <p style={{ fontWeight: "700" }}>
        Distribution Worldwide
      </p>
      <Box h='400px' minW='100%' mt='12px'>
        {data ? <ParentSize>{({ width, height }) => <Map data={data} width={width} height={height} />}</ParentSize> : []}
      </Box>
    </Card>
  );
}