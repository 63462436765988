import {
  Flex,
  Box,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

// Custom components
import Card from 'components/card/Card';
import { useUsageData } from 'api';
import { beautifulNumber } from '..';


const getUsageDescription = (usageType) => {
  switch (usageType) {
    case 'COM':
      return 'Commercial';
    case 'ORG':
      return 'Organization';
    case 'GOV':
      return 'Government';
    case 'MIL':
      return 'Military';
    case 'EDU':
      return 'University/College/School';
    case 'LIB':
      return 'Library';
    case 'CDN':
      return 'Content Delivery Network';
    case 'ISP':
      return 'Fixed Line ISP';
    case 'MOB':
      return 'Mobile ISP';
    case 'DCH':
      return 'Data Center/Web Hosting/Transit';
    case 'SES':
      return 'Search Engine Spider';
    case 'RSV':
      return 'Reserved';
    default:
      return 'Unknown';
  }
}

const columnHelper = createColumnHelper();


export default function CheckTable(props) {
  const [sorting, setSorting] = React.useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const {data} = useUsageData();

  const columns = [
    columnHelper.accessor('usageType', {
      id: 'usageType',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Usage Type
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Checkbox
            defaultChecked={info.getValue()[1]}
            colorScheme="brandScheme"
            me="10px"
          />
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
          <span>
            &nbsp;({getUsageDescription(info.getValue())})
          </span>
        </Flex>
      ),
    }),
    columnHelper.accessor('purchase', {
      id: 'purchase',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Purchases
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {beautifulNumber(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor('view', {
      id: 'view',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Views
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {beautifulNumber(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor('install', {
      id: 'install',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Installs
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {beautifulNumber(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor('signup', {
      id: 'signup',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
        Signups
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {beautifulNumber(info.getValue())}
        </Text>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <p style={{ fontWeight: "700" }}>
          Ip usage
        </p>
      </Flex>
      <Box h='400px' minW='100%' mt='12px'>
        {data ? 
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows.slice(0, 5)
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table> : null
      }
      </Box>
    </Card>
  );
}
