import {
  Box,
  Flex,
  Icon,
  GridItem,
  Grid,
  useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import PowerByDayChart from "views/admin/iot/components/PowerByDayChart";
import PowerByDeviceChart from "views/admin/iot/components/PowerByDeviceChart";
import TemperatureChart from "views/admin/iot/components/TemperatureChart";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import {
  MdOutlineWater,
  MdElectricBolt,
  MdOutlineGasMeter,
  MdOutlineAir
} from "react-icons/md";

export default function IotReports() {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Grid
        templateColumns='repeat(6, 1fr)'
        gap='20px' 
      >
        <GridItem rowSpan={1} colSpan={4}>
          <Flex mb="20px" gap="20px">
            <MiniStatistics
              startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={
                    <Icon w='32px' h='32px' as={MdElectricBolt} color={brandColor} />
                  }
                />
              }
              name='Electricity'
              value='3928kW'
              growth='+3%'
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={
                    <Icon w='32px' h='32px' as={MdOutlineWater} color={brandColor} />
                  }
                />
              }
              name='Water'
              value='780L'
              growth='+13%'
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={
                    <Icon w='32px' h='32px' as={MdOutlineGasMeter} color={brandColor} />
                  }
                />
              }
              growth='+5%'
              name='Gas'
              value='208m³' 
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={
                    <Icon w='32px' h='32px' as={MdOutlineAir} color={brandColor} />
                  }
                />
              }
              name='Air quality'
              value='90%' 
            />
          </Flex>
          <TemperatureChart />
        </GridItem>
        <GridItem colSpan={2}>
          <PowerByDayChart />
          <PowerByDeviceChart />
        </GridItem>
      </Grid>
    </Box>
  );
}
