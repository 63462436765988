import {
  Box,
  Flex,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useMemo, useState } from 'react';
import { Group } from '@visx/group';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { Arc } from '@visx/shape';
import { scaleBand, scaleRadial } from '@visx/scale';
import { Text } from '@visx/text';
import { usePowerByDay } from "api";


const getDay = (d) => d.day;
const getDayPower = (d) => Number(d.power) * 100;

const frequencySort = (a, b) => b.frequency - a.frequency;

const toDegrees = (x) => (x * 180) / Math.PI;

const barColor = '#A0AEC0';
const margin = { top: 20, bottom: 20, left: 20, right: 20 };

function PieComponent({ width, height, data }) {
  // eslint-disable-next-line no-unused-vars
  const [rotation, _setRotation] = useState(0);

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const radiusMax = Math.min(xMax, yMax) / 2;

  const innerRadius = radiusMax / 3;

  const xDomain = useMemo(
    () => data.sort(frequencySort).map(getDay),
    [data],
  );

  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0 + rotation, 2 * Math.PI + rotation],
        domain: xDomain,
        padding: 0.2,
      }),
    [rotation, xDomain],
  );

  const yScale = useMemo(
    () =>
      scaleRadial({
        range: [innerRadius, radiusMax],
        domain: [0, Math.max(...data.map(getDayPower))],
      }),
    [innerRadius, radiusMax, data],
  );

  return width < 10 ? null : (
    <>
      <svg width={width} height={height}>
        <rect width={width} height={height} fill="white" rx={14} />
        <Group top={yMax / 2 + margin.top} left={xMax / 2 + margin.left}>
          {data.map((d) => {
            const letter = getDay(d);
            const startAngle = xScale(letter);
            const midAngle = startAngle + xScale.bandwidth() / 2;
            const endAngle = startAngle + xScale.bandwidth();

            const outerRadius = yScale(getDayPower(d)) ?? 0;

            // convert polar coordinates to cartesian for drawing labels
            const textRadius = outerRadius + 4;
            const textX = textRadius * Math.cos(midAngle - Math.PI / 2);
            const textY = textRadius * Math.sin(midAngle - Math.PI / 2);

            return (
              <>
                <Arc
                  key={`bar-${letter}`}
                  cornerRadius={4}
                  startAngle={startAngle}
                  endAngle={endAngle}
                  outerRadius={outerRadius}
                  innerRadius={innerRadius}
                  fill={barColor}
                />
                <Text
                  x={textX}
                  y={textY}
                  dominantBaseline="end"
                  textAnchor="middle"
                  fontSize={16}
                  fontWeight="bold"
                  fill={barColor}
                  angle={toDegrees(midAngle)}
                >
                  {letter}
                </Text>
              </>
            );
          })}
        </Group>
      </svg>
      <style jsx>{`
        .controls {
          font-size: 14px;
          line-height: 1.5em;
        }
      `}</style>
    </>
  );
}


export default function PieChart(props) {
  const { ...rest } = props;
  const {data} = usePowerByDay();

  return (
    <Card
      w='100%'
      mb='20px'
      p='20px'
      {...rest}
    >
      <p style={{ fontWeight: "700" }}>
        Elictricity usage by day
      </p>
      <Flex>
        <Box h='280px' minW='70%' mt='auto'>
          {data?.length ? <ParentSize>{({ width, height }) => <PieComponent data={data} width={width} height={height} />}</ParentSize>: null}
        </Box>
        <Box  w='40%' h='100%' p='10px'>
          {data?.length ? (data.map(row => {
            return (
              <p style={{ paddingBottom: "4px" }} key={row.day}>
                {row.day}&nbsp;&nbsp;
                <span style={{ fontWeight: "700" }}>
                  {row.power} kW
                </span>
              </p>            
            )
          })) : null}
        </Box>
      </Flex>
  </Card>
  );
}