import React from "react";

// Chakra imports
import { Flex } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode

  return (
    <Flex align='center' direction='column'>
      <Flex className="logo" align='center' direction='row' mb={25} fontSize={24} fontWeight={"bold"}>
        <img src="/logo.svg" alt="MeasureBeast" className="logo" style={{ marginRight: 10}}/>
        <span>MeasureBeast</span>
      </Flex>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
