import {
  Box,
  Flex,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from 'react';
import { Group } from '@visx/group';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { BarStackHorizontal } from '@visx/shape';
import { AxisLeft } from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { withTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { usePowerByDevice } from "api";

const purple1 = '#c998ff';
const purple2 = '#c998ff';
export const purple3 = '#a44afe';
export const background = 'white';
const defaultMargin = { top: 8, left: 0, right: 40, bottom: 0 };
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

const BarComponent = withTooltip(
  ({
    width,
    height,
    events = false,
    data,
    margin = defaultMargin,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  }) => {
    const keys = Object.keys(data[0]).filter((d) => d !== 'device' && d!== 'order');
    const temperatureTotals = data.reduce((allTotals, currentDate) => {
      const totalTemperature = keys.reduce((dailyTotal, k) => {
        dailyTotal += Number(currentDate[k]);
        return dailyTotal;
      }, 0);
      allTotals.push(totalTemperature);
      return allTotals;
    }, []);

    // accessors
    const getDevice = (d) => d.device;

    // scales
    const temperatureScale = scaleLinear({
      domain: [0, Math.max(...temperatureTotals)],
      nice: true,
    });
    const dateScale = scaleBand({
      domain: data.map(getDevice),
      padding: 0.2,
    });
    const colorScale = scaleOrdinal({
      domain: keys,
      range: [purple1, purple2, purple3],
    });

    let tooltipTimeout;

    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;
    temperatureScale.rangeRound([0, xMax]);
    dateScale.rangeRound([yMax, 0]);

    return width < 10 ? null : (
      <div>
        <svg width={width} height={height}>
          <rect width={width} height={height} fill={background} rx={14} />
          <Group top={margin.top} left={margin.left}>
            <BarStackHorizontal
              data={data}
              keys={keys}
              height={yMax}
              y={getDevice}
              xScale={temperatureScale}
              yScale={dateScale}
              color={colorScale}
            >
              {(barStacks) =>
                barStacks.map((barStack) =>
                  barStack.bars.map((bar) => (
                    <rect
                      key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                      x={bar.x}
                      y={bar.y}
                      width={bar.width}
                      height={bar.height}
                      fill={bar.color}
                      onClick={() => {
                        if (events) alert(`clicked: ${JSON.stringify(bar)}`);
                      }}
                      onMouseLeave={() => {
                        tooltipTimeout = window.setTimeout(() => {
                          hideTooltip();
                        }, 300);
                      }}
                      onMouseMove={() => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                        const top = bar.y + margin.top;
                        const left = bar.x + bar.width + margin.left;
                        showTooltip({
                          tooltipData: bar,
                          tooltipTop: top,
                          tooltipLeft: left,
                        });
                      }}
                    />
                  )),
                )
              }
            </BarStackHorizontal>
            <AxisLeft
              hideAxisLine
              hideTicks
              left={20}
              scale={dateScale}
              // tickFormat={formatDate}
              // stroke={purple3}
              // tickStroke={purple3}
              tickLabelProps={{
                fill: 'black',
                fontSize: 14,
                textAnchor: 'start',
                dy: '0.33em',
              }}
            />           
          </Group>
        </svg>
        {tooltipOpen && tooltipData && (
          <Tooltip top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
            <div>{tooltipData.bar.data[tooltipData.key]}W</div>
            <div>
              <small>{getDevice(tooltipData.bar.data)}</small>
            </div>
          </Tooltip>
        )}
      </div>
    );
  },
);




export default function BarChart(props) {
  const { ...rest } = props;
  const {data} = usePowerByDevice();

  return (
    <Card
      w='100%'
      p='20px'
      {...rest}
    >
      <p style={{ fontWeight: "700" }}>
        Elictricity usage by device
      </p>
      <Flex>
        <Box h='230px' minW='100%' mt='auto'>
          {data?.length ? <ParentSize>{({ width, height }) => <BarComponent data={data} width={width} height={height} />}</ParentSize>: null}
        </Box>
      </Flex>
    </Card>
  );
}