import { useQuery } from '@tanstack/react-query';
import cityTemperature from '@visx/mock-data/lib/mocks/cityTemperature';

const prizepicks_events = require('./prizepicks_events.json');
const power_by_day = require('./power_by_day.json');
const power_by_device = require('./power_by_device.json');
const topology = require('./world-topo.json');
const usage = require('./usage.json');


export const fetchAdvertiserEvents = async () => {
    const randomNumber = Math.floor(Math.random() * 3) + 1;
    let data = null
    data = prizepicks_events.reduce((acc, item) => {
        if (!acc[item.action]) {
            acc[item.action] = []
        }
        acc[item.action].push({...item, count: item.count + String(randomNumber + 1)});
        return acc
    }, {});


    data.totalPurchases = data.purchase.reduce((acc, item) => { return acc + Number(item.count) }, 0);
    data.totalViews = data.view.reduce((acc, item) => { return acc + Number(item.count) }, 0);
    data.totalInstalls = data.install.reduce((acc, item) => { return acc + Number(item.count) }, 0);
    data.totalSignups = data.signup.reduce((acc, item) => { return acc + Number(item.count) }, 0);
    data.totalRevenue = data.purchase.reduce((acc, item) => { return acc + item.revenue }, 0);


    await new Promise((resolve) => setTimeout(resolve, randomNumber * 1000));
    
    return data
}

export const useAdvertiserEvents = () => useQuery({
  queryKey: ['ADVERTISER_EVENTS'],
  queryFn: () => fetchAdvertiserEvents() || {},
  retry: 0,
});


export const fetchPowerByDay = async () => {
    const data = power_by_day.sort((a, b) => a.order - b.order);
    const randomNumber = Math.floor(Math.random() * 3) + 1;

    await new Promise((resolve) => setTimeout(resolve, randomNumber * 1000));
    
    return data.map(row => ({ ...row, power: Math.round((row.power * 4210)) }))
}


export const usePowerByDay = () => useQuery({
    queryKey: ['POWER_BY_DAY'],
    queryFn: () => fetchPowerByDay() || [],
    retry: 0,
});


export const fetchPowerByDevice = async () => {
    const data = power_by_device.sort((a, b) => a.order - b.order);
    const randomNumber = Math.floor(Math.random() * 3) + 1;

    await new Promise((resolve) => setTimeout(resolve, randomNumber * 1000));
    return data.map(row => ({ ...row, power: Math.round((row.power * 4210)) })).sort((a, b) => a.power - b.power)
}


export const usePowerByDevice = () => useQuery({
    queryKey: ['POWER_BY_DEVICE'],
    queryFn: () => fetchPowerByDevice() || [],
    retry: 0,
});



const fetchAverageTemperature = async () => {

    const randomNumber = Math.floor(Math.random() * 3) + 1;
    const data = cityTemperature.map(row => ({ ...row, 'New York': Math.round((row['New York'] - 32) * 5 / 9), 'San Francisco': Math.round((row['San Francisco'] - 32) * 5 / 9), 'Austin': Math.round((row['Austin'] - 32) * 5 / 9) }));
    await new Promise((resolve) => setTimeout(resolve, randomNumber * 1000));
    return data
    
}

export const useAverageTemperature = () => useQuery({
    queryKey: ['AVERAGE_TEMPERATURE'],
    queryFn: () => fetchAverageTemperature() || [],
    retry: 0,
});


const fetchTopologyData = async () => {
    const randomNumber = Math.floor(Math.random() * 3) + 1;
    await new Promise((resolve) => setTimeout(resolve, randomNumber * 1000));
    return topology    
}

export const useTopologyData = () => useQuery({
    queryKey: ['TOPOLOGY'],
    queryFn: () => fetchTopologyData() || [],
    retry: 0,
});


const fetchUsageData = async () => {
    const randomNumber = Math.floor(Math.random() * 3) + 1;
    await new Promise((resolve) => setTimeout(resolve, randomNumber * 1000));
    return usage    
}

export const useUsageData = () => useQuery({
    queryKey: ['USAGE'],
    queryFn: () => fetchUsageData() || [],
    retry: 0,
});

