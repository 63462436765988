import {
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import UsageTable from "views/admin/ecommerce/components/UsageTable";
import PieChart from "views/admin/ecommerce/components/PieChart";
import EventsChart from "views/admin/ecommerce/components/EventsChart";
import Usa from "views/admin/ecommerce/components/Usa";
import Worldwide from "views/admin/ecommerce/components/Worldwide";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import {
  MdPayments,
  MdInstallMobile,
  MdOutlinePreview,
  MdAppRegistration
} from "react-icons/md";
import { useAdvertiserEvents } from "api";


export function beautifulNumber(num) {
  if (!num) return;

  if (num >= 1e12) {
      return (num / 1e12).toFixed(1).replace(/\.0$/, '') + 'T'; // Trillions
  }
  if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B';  // Billions
  }
  if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';  // Millions
  }
  if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'K';  // Thousands
  }
  return num.toString();  // Less than 1000
}

export default function UserReports() {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const { data = [] } = useAdvertiserEvents();

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdOutlinePreview} color={brandColor} />
              }
            />
          }
          name='Views'
          value={beautifulNumber(data.totalViews)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdInstallMobile} color={brandColor} />
              }
            />
          }
          name='Installs'
          value={beautifulNumber(data.totalInstalls)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAppRegistration} color={brandColor} />
              }
            />
          }
          name='Signups'
          value={beautifulNumber(data.totalSignups)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdPayments} color={brandColor} />
              }
            />
          }
          name='Purchases'
          value={beautifulNumber(data.totalPurchases)}
        />

        <MiniStatistics growth='+23%' name='Revenue' value={data.totalRevenue ? `$${beautifulNumber(data.totalRevenue)}`: ''} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        <EventsChart />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <Usa />
        <Worldwide />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <UsageTable  />
        <PieChart />
      </SimpleGrid>
    </Box>
  );
}
