import {
  Box,
  Flex,
  Select,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";

import React, { useMemo, useState } from 'react';
import Pie from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { GradientPinkBlue } from '@visx/gradient';
import { animated, useTransition, interpolate } from '@react-spring/web';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import ua from './ua.json';
import devices from './devices.json';

const usage = (d) => d.value;
const frequency = (d) => d.value;

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

function PieComponent({
  width,
  height,
  uaData,
  deviceData,
  margin = defaultMargin,
  animate = true,
}) {
  const getDeviceColor = useMemo(() => scaleOrdinal({
    domain: deviceData.map(device => device.label),
    range: [
     'rgba(93,30,91,1)', 'rgba(93,30,91,0.8)', 'rgba(93,30,91,0.6)', 'rgba(93,30,91,0.4)', 'rgba(93,30,91,0.4)'
    ],
  }), [deviceData]);

  const getUaColor = useMemo(() => scaleOrdinal({
    domain: uaData.map((u) => u.label),
    range: ['rgba(93,30,91,1)', 'rgba(93,30,91,0.8)', 'rgba(93,30,91,0.6)', 'rgba(93,30,91,0.4)'],
  }), [uaData]);


  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 50;

  return (
    <svg width={width} height={height}>
      <GradientPinkBlue id="visx-pie-gradient" />
      <rect rx={14} width={width} height={height} fill="white" />
      <Group top={centerY + margin.top} left={centerX + margin.left}>
        <Pie
          data={deviceData}
          pieValue={usage}
          outerRadius={radius}
          innerRadius={radius - donutThickness}
          cornerRadius={3}
          padAngle={0.005}
        >
          {(pie) => (
            <AnimatedPie
              {...pie}
              animate={animate}
              getKey={(arc) => arc.data.label}
              onClickDatum={() => null}
              getColor={(arc) => getDeviceColor(arc.data.label)}
            />
          )}
        </Pie>
        <Pie
          data={uaData}
          pieValue={frequency}
          pieSortValues={() => -1}
          outerRadius={radius - donutThickness * 1.3}
        >
          {(pie) => (
            <AnimatedPie
              {...pie}
              animate={animate}
              getKey={({ data: { label } }) => label}
              onClickDatum={() => null}
              getColor={({ data: { label } }) => getUaColor(label)}
            />
          )}
        </Pie>
      </Group>
    </svg>
  );
}

const fromLeaveTransition = ({ endAngle }) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0.5,
});
const enterUpdateTransition = ({ startAngle, endAngle }) => ({
  startAngle,
  endAngle,
  opacity: 0.5,
});


function AnimatedPie({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  onClickDatum,
}) {
  const transitions = useTransition(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });
  return transitions((props, arc, { key }) => {
    const [centroidX, centroidY] = path.centroid(arc);
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;

    return (
      <g key={key}>
        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={interpolate([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            }),
          )}
          fill={getColor(arc)}
          onClick={() => onClickDatum(arc)}
          onTouchStart={() => onClickDatum(arc)}
        />
        {hasSpaceForLabel && (
          <animated.g style={{ opacity: 1 }}>
            <text
              fill="white"
              x={centroidX}
              y={centroidY}
              dy=".33em"
              fontSize={12}
              textAnchor="center"
              pointerEvents="none"
            >
              {getKey(arc)}
            </text>
          </animated.g>
        )}
      </g>
    );
  });
}


export default function PieChart(props) {
  const { ...rest } = props;

  const [action, setAction] = useState('view');


  const uaEvents = ua.reduce((acc, data) => {
    acc.purchase.push({label: data.ua, value: data.purchase || 0});
    acc.view.push({label: data.ua, value: data.view || 0});
    acc.install.push({label: data.ua, value: data.install || 0});
    acc.signup.push({label: data.ua, value: data.signup || 0});
    return acc
  }, {purchase: [], view: [], install: [], signup: []});
  
  const deviceEvents = devices.reduce((acc, data) => {
    acc.purchase.push({label: data.device, value: data.purchase || 0});
    acc.view.push({devilabelce: data.device, value: data.view || 0});
    acc.install.push({label: data.device, value: data.install || 0});
    acc.signup.push({label: data.device, value: data.signup || 0});
    return acc
  }, {purchase: [], view: [], install: [], signup: []});


  const uaData = (uaEvents[action] || []).sort((a, b) => new Date(a.date) - new Date(b.date));
  const deviceData = (deviceEvents[action] || []).sort((a, b) => new Date(a.date) - new Date(b.date));

  const onRadioChange = (event) => {
    setAction(event.target.value)
  };

  return (
    <Card
      justifyContent='center'
      direction={{ base: "row", lg: "row" }}
      align='top'
      w='100%'
      mb='0px'
      p='20px'
      {...rest}
    >
      <Flex justifyContent="space-between" mb="12px">
        <p style={{ fontWeight: "700" }}>
          Device usage
        </p>
        <Select placeholder='Select action' w="200px" onChange={onRadioChange} value={action}>
          <option value='view'>View</option>
          <option value='install'>Install</option>
          <option value='signup'>Signup</option>
          <option value='purchase'>Purchase</option>
        </Select>
      </Flex>
      <Flex w='75%' >
        <Box h='400px' minW='100%' mt='auto'>
        <ParentSize>{({ width, height }) => <PieComponent uaData={uaData} deviceData={deviceData} width={width} height={height} />}</ParentSize>
      </Box>
    </Flex>
  </Card>
  );
}