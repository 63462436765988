import {
  Box,
  Flex,
  Select
} from "@chakra-ui/react";

import Card from "components/card/Card.js";
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import {  AxisBottom } from '@visx/axis';


import { scaleTime, scaleLinear } from '@visx/scale';
import { LinearGradient } from '@visx/gradient';
import { withTooltip, Tooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';
import React, { useMemo, useState } from 'react';
import { AreaClosed, Line, Bar } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { GridRows, GridColumns } from '@visx/grid';
import { localPoint } from '@visx/event';
import { max, extent, bisector } from '@visx/vendor/d3-array';
import { timeFormat } from '@visx/vendor/d3-time-format';
import {beautifulNumber} from '../index';

import { useAdvertiserEvents } from "api";
export const accentColor = '#f6acc8';
export const background = 'white';
export const background2 = '#af8baf';

const accentColorDark = '#75daad';
const tooltipStyles = {
  ...defaultStyles,
  background,
  border: '1px solid white',
  color: 'black',
};

// util
const formatDate = timeFormat("%b %d, '%y");

// accessors
const getDate = (d) => new Date(d.date);
const bisectDate = bisector((d) => new Date(d.date)).left;
const getDataValue = (d) => Number(d.count);

const defaultMargin = { top: 40, right: 20, bottom: 32, left: 20 };

const BrushChart = withTooltip(
  ({
    width,
    height,
    data,
    margin = defaultMargin,
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
  }) => {
    if (width < 10) return null;

    // bounds
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // scales
    const dateScale = useMemo(
      () =>
        scaleTime({
          range: [margin.left, innerWidth + margin.left],
          domain: extent(data, getDate),
        }),
      [innerWidth, margin.left, data],
    );

    const dataValueScale = useMemo(
      () => {
        return scaleLinear({
          range: [innerHeight + margin.top, margin.top],
          domain: [0, (max(data, getDataValue) || 0) ],
          nice: true,
        })
      },
      [margin.top, innerHeight, data],
    );


    // tooltip handler
    const handleTooltip = (event) => {
        const { x } = localPoint(event) || { x: 0 };
        const x0 = dateScale.invert(x);
        const index = bisectDate(data, x0, 1);
        const d0 = data[index - 1];
        const d1 = data[index];
        let d = d0;
        if (d1 && getDate(d1)) {
          d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0;
        }
        showTooltip({
          tooltipData: d,
          tooltipLeft: x,
          tooltipTop: dataValueScale(getDataValue(d)),
        });
      }

    return (
      <div>
        <svg width={width} height={height}>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill="white"
            rx={14}
          />
          <LinearGradient id="area-background-gradient" from={background} to={background2} />
          <LinearGradient id="area-gradient" from={accentColor} to={accentColor} toOpacity={0.5} />
          <GridRows
            left={margin.left}
            scale={dataValueScale}
            width={innerWidth}
            strokeDasharray="1,3"
            stroke={accentColor}
            // strokeOpacity={0}
            pointerEvents="none"
          />
          <GridColumns
            top={margin.top}
            scale={dateScale}
            height={innerHeight}
            strokeDasharray="1,3"
            stroke={accentColor}
            // strokeOpacity={0.2}
            pointerEvents="none"
          />

          <AreaClosed
            data={data}
            x={(d) => dateScale(getDate(d)) ?? 0}
            y={(d) => dataValueScale(getDataValue(d)) ?? 0}
            yScale={dataValueScale}
            strokeWidth={1}
            stroke="url(#area-gradient)"
            fill="url(#area-gradient)"
            curve={curveMonotoneX}
          />
         
          <Bar
            x={margin.left}
            y={margin.top}
            width={innerWidth}
            height={innerHeight}
            fill="transparent"
            rx={14}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />
          <AxisBottom top={height- margin.bottom} scale={dateScale} numTicks={width > 520 ? 10 : 5} orientation="bottom" />
          
          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: margin.top }}
                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                stroke={accentColorDark}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={accentColorDark}
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
        </svg>
        {tooltipData && (
          <div>
            <TooltipWithBounds
              key={Math.random()}
              top={tooltipTop - 12}
              left={tooltipLeft + 12}
              style={tooltipStyles}
            >
              {`${beautifulNumber(getDataValue(tooltipData))}`}
            </TooltipWithBounds>
            <Tooltip
              top={innerHeight + margin.top - 14}
              left={tooltipLeft}
              style={{
                ...defaultStyles,
                minWidth: 72,
                textAlign: 'center',
                transform: 'translateX(-50%)',
              }}
            >
              {formatDate(getDate(tooltipData))}
            </Tooltip>
          </div>
        )}
      </div>
    );
  },
);



export default function EventsChart(props) {
  const { ...rest } = props;

  const [action, setAction] = useState('view');
  const { data = [] } = useAdvertiserEvents();

  const onRadioChange = (event) => {
    setAction(event.target.value)
  };

  const actionData = (data[action] || []).sort((a, b) => new Date(a.date) - new Date(b.date));


  return (
    <Card
      justifyContent='center'
      align='left'
      direction='column'
      w='100%'
      mb='0px'
      {...rest}
    >
      <Flex justifyContent="space-between" mb="12px">
        <p style={{ fontWeight: "700" }}>
          Events
        </p>
        <Select placeholder='Select action' w="200px" onChange={onRadioChange} value={action}>
          <option value='view'>View</option>
          <option value='install'>Install</option>
          <option value='signup'>Signup</option>
          <option value='purchase'>Purchase</option>
        </Select>
      </Flex>
    
      <Flex minW='15%' flexDirection={{ base: "column", lg: "row" }}>
        <Box h='400px' minW='100%' mt='auto'>
          {actionData.length ? <ParentSize>{({ width, height }) => <BrushChart action={action} data={actionData} width={width} height={height} />}</ParentSize> : null}
        </Box>
      </Flex>
    </Card>
  );
}
