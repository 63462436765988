import {
  Box,
} from "@chakra-ui/react";

import Card from "components/card/Card.js";
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { scaleTime, scaleLinear } from '@visx/scale';
import React from 'react';
import { GridRows, GridColumns } from '@visx/grid';
import { useAverageTemperature } from "api";
import { Group } from '@visx/group';
import { curveBasis } from '@visx/curve';
import { LinePath } from '@visx/shape';
import { Threshold } from '@visx/threshold';
import { AxisLeft, AxisBottom } from '@visx/axis';

export const accentColor = '#f6acc8';
export const background = 'white';
export const background2 = '#af8baf';

// accessors
const date = (d) => new Date(d.date).valueOf();
const ny = (d) => Number(d['New York']);
const sf = (d) => Number(d['San Francisco']);

// scales


const defaultMargin = { top: 40, right: 30, bottom: 50, left: 40 };

function ThesholdCmp({ width, height, data, margin = defaultMargin }) {
  if (width < 10) return null;
  
  const timeScale = scaleTime({
    domain: [Math.min(...data.map(date)), Math.max(...data.map(date))],
  });
  const temperatureScale = scaleLinear({
    domain: [
      Math.min(...data.map((d) => Math.min(ny(d), sf(d)))),
      Math.max(...data.map((d) => Math.max(ny(d), sf(d)))),
    ],
    nice: true,
  });

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  timeScale.range([0, xMax]);
  temperatureScale.range([yMax, 0]);

  return (
    <div>
      <svg width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} fill={background} rx={14} />
        <Group left={margin.left} top={margin.top}>
          <GridRows scale={temperatureScale} width={xMax} height={yMax} stroke="#e0e0e0" />
          <GridColumns scale={timeScale} width={xMax} height={yMax} stroke="#e0e0e0" />
          <line x1={xMax} x2={xMax} y1={0} y2={yMax} stroke="#e0e0e0" />
          <AxisBottom top={yMax} scale={timeScale} numTicks={width > 520 ? 10 : 5} />
          <AxisLeft scale={temperatureScale} />
          <text x="-70" y="15" transform="rotate(-90)" fontSize={10}>
            Temperature (°C)
          </text>
          <Threshold
            id={`${Math.random()}`}
            data={data}
            x={(d) => timeScale(date(d)) ?? 0}
            y0={(d) => temperatureScale(ny(d)) ?? 0}
            y1={(d) => temperatureScale(sf(d)) ?? 0}
            clipAboveTo={0}
            clipBelowTo={yMax}
            curve={curveBasis}
            belowAreaProps={{
              fill: 'violet',
              fillOpacity: 0.4,
            }}
            aboveAreaProps={{
              fill: 'green',
              fillOpacity: 0.4,
            }}
          />
          <LinePath
            data={data}
            curve={curveBasis}
            x={(d) => timeScale(date(d)) ?? 0}
            y={(d) => temperatureScale(sf(d)) ?? 0}
            stroke="#222"
            strokeWidth={1.5}
            strokeOpacity={0.8}
            strokeDasharray="1,2"
          />
          <LinePath
            data={data}
            curve={curveBasis}
            x={(d) => timeScale(date(d)) ?? 0}
            y={(d) => temperatureScale(ny(d)) ?? 0}
            stroke="#222"
            strokeWidth={1.5}
          />
        </Group>
      </svg>
    </div>
  );
}



export default function EventsChart(props) {
  const { ...rest } = props;

  const { data = [] } = useAverageTemperature();
  return (
    <Card
      w='100%'
      mb='0px'
      {...rest}
    >
      <p style={{ fontWeight: "700" }}>
        Average warehouse temperature
      </p>
      <Box h='480px' minW='100%' mt='auto'>
        {data.length ?  <ParentSize>{({ width, height }) => <ThesholdCmp data={data} width={width} height={height} />}</ParentSize> : null}
      </Box>
    </Card>
  );
}
